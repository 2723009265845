import Axios from "axios";
import useAnalytics from "hooks/analytics";
import useAuthToken from "hooks/auth-token";
import useLanguage from "hooks/language";
import { TfaPreference } from "models/user";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserActionEvent } from "utils/analytics/events";
import {
    EMAIL_REGEX,
    HOTMAIL_REGEX,
    PHONE_NUMBER_REGEX,
} from "utils/validation";
import signupIconSrc from "../../assets/images/signup-icon.svg";
import {
    Button,
    Checkbox,
    Field,
    Footer,
    Header,
    Option,
    PasswordValidator,
    Select,
} from "../../components";
import { Selection } from "../../components/select";
import { shuffle } from "../../utils/array";
import ENDPOINTS from "../../utils/endpoints";
import ROUTES from "../../utils/routes";
import ResendConfirmation from "./resend-confirmation";
import styles from "./sign-up.module.css";

const OTHER_REASON_MAX_INDEX = 254;

const SignUpPage: React.FunctionComponent = () => {
    const [analytics] = useAnalytics();
    const [authToken] = useAuthToken();
    const { search } = useLocation();
    const { push } = useHistory();
    const { language, translations } = useLanguage();
    const signUpLocalization = translations.signUp;

    const startingFieldValue = (undefined as any) as string;
    const [firstName, setFirstName] = useState<string>(startingFieldValue);
    const [lastName, setLastName] = useState(startingFieldValue);
    const [email, setEmail] = useState(startingFieldValue);
    const [password, setPassword] = useState(startingFieldValue);
    const [passwordValid, setPasswordValid] = useState(false);
    const [
        passwordDescriptionVisible,
        setPasswordDescriptionVisible,
    ] = useState(false);
    const [tfaPreference, setTfaPreference] = useState<TfaPreference>("email");
    const [phoneNumber, setPhoneNumber] = useState(startingFieldValue);
    const [consentChecked, setConsentChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [created, setCreated] = useState(false);
    const [surveyAnswer, setSurveyAnswer] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const [otherOptionSelected, setOtherOptionSelected] = useState(false);
    // NOTE: FileValidation state starts with onLoad property, since user is not meant to click the "I am registering" button until
    // all fields of the Sign UP form have been validated and filled
    const fieldValidationStateRef = useRef<{ [key: string]: boolean }>({
        onLoad: true,
    });

    useEffect(() => {
        if (authToken) {
            push(ROUTES.HOME);
            return;
        }

        analytics?.cdp()?.disconnectUser();
        analytics?.cdp()?.identifyAnonymousUser();
        analytics?.cdp()?.trackEvent(UserActionEvent.SignUpInitiated);
        trackMFASetupInitiated(tfaPreference);
    }, []);

    const signupSurveyOptions = Object.keys(
        signUpLocalization.howDidYouHearAboutUs.options
    );

    const validateField = (
        fieldID: string,
        value: string,
        fieldType: "field" | "email" | "password" | "phone",
        executeValidation: boolean | undefined
    ): string | null => {
        let errorMessage: string | undefined;

        if (!executeValidation) {
            const {
                [fieldID]: test,
                ...updatedState
            } = fieldValidationStateRef.current;
            fieldValidationStateRef.current = { ...updatedState };

            return null;
        }

        switch (fieldType) {
            case "email":
                if (!EMAIL_REGEX.test(value)) {
                    errorMessage = signUpLocalization.invalidEmailError;
                }

                break;
            case "password":
                if (!passwordValid) {
                    errorMessage = signUpLocalization.invalidPasswordError;
                }

                break;
            case "phone":
                if (!PHONE_NUMBER_REGEX.test(value)) {
                    errorMessage = signUpLocalization.phoneNumberError;
                }

                break;
            default:
                if (value?.length < 1) {
                    errorMessage = signUpLocalization.requiredFieldError;
                }

                break;
        }

        if (errorMessage) {
            fieldValidationStateRef.current[fieldID] = true;

            return errorMessage;
        }

        const {
            [fieldID]: test,
            onLoad,
            ...updatedState
        } = fieldValidationStateRef.current;
        fieldValidationStateRef.current = { ...updatedState };

        return null;
    };

    const onSignUpSurveySelection = (option: Selection): void => {
        const isOtherOptionSelected = option.key === "other";

        setSurveyAnswer(option.key as string);
        setOtherOptionSelected(isOtherOptionSelected);
    };

    const trackMFASetupInitiated = (pref: TfaPreference): void => {
        const eventProperies = { method: pref };

        analytics
            ?.cdp()
            ?.trackEvent(UserActionEvent.MFASetupInitiated, eventProperies);
    };
    const onTFAOptionChanged = (pref: TfaPreference) => {
        trackMFASetupInitiated(pref);
        setTfaPreference(pref);
    };

    const createAccount = async () => {
        setLoading(true);

        const searchParams = new URLSearchParams(search);
        const invitationToken =
            searchParams.get("invitationToken") || undefined;

        try {
            const payload: any = {
                email,
                firstName,
                lastName,
                password,
                tfaPreference,
                phoneNumber,
                invitationToken,
            };

            if (surveyAnswer) {
                payload.signupSurvey = {
                    selectedAnswer: surveyAnswer,
                };

                if (otherOptionSelected && otherReason) {
                    payload.signupSurvey.otherReason = otherReason;
                }
            }

            await Axios.post(ENDPOINTS.SIGNUP, payload);

            const eventProperties = {
                url: `${window.location.origin}${ROUTES.APPOINTMENTS}`,
            };
            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.SignUpCompleted, eventProperties);
            analytics?.web()?.trackEvent(UserActionEvent.SignUpCompleted);
            analytics
                ?.cdp()
                ?.identifyUser(
                    firstName,
                    email,
                    phoneNumber,
                    language,
                    "",
                    "",
                    ""
                );

            setCreated(true);
        } finally {
            setLoading(false);
        }
    };

    if (created) {
        return (
            <ResendConfirmation
                email={email}
                localizationDictionary={signUpLocalization}
            />
        );
    }

    return (
        <div className="page">
            <Header />

            <div className={`${styles.container} preauth-container`}>
                <div>
                    <div className={styles.imgHolder}>
                        <img src={signupIconSrc} />
                    </div>

                    <div>
                        <h2 className={`${styles["single-line"]}`}>
                            {signUpLocalization.header}
                        </h2>
                        <div
                            className={`${styles.subtitle} ${styles["single-line"]}`}
                        >
                            <span>{signUpLocalization.alreadyHasAccount}</span>
                            <b>
                                <a
                                    className={`${styles.subtitle}`}
                                    onClick={() => push(ROUTES.LOGIN)}
                                >
                                    {signUpLocalization.login}
                                </a>
                            </b>
                        </div>
                    </div>

                    <div className="input-holder">
                        <label>
                            {signUpLocalization.firstNameLabel}
                            <span
                                className={`disclaimer ${styles["input-label-hint"]}`}
                            >
                                {signUpLocalization.nameWarning}
                            </span>
                        </label>

                        <Field
                            value={firstName}
                            onBlur={() =>
                                firstName === startingFieldValue &&
                                setFirstName("")
                            }
                            onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            error={validateField(
                                "firstNameField",
                                firstName,
                                "field",
                                firstName !== startingFieldValue
                            )}
                        />
                    </div>

                    <div className="input-holder">
                        <label>
                            {signUpLocalization.lastNameLabel}
                            <span
                                className={`disclaimer ${styles["input-label-hint"]}`}
                            >
                                {signUpLocalization.nameWarning}
                            </span>
                        </label>

                        <Field
                            value={lastName}
                            onBlur={() =>
                                lastName === startingFieldValue &&
                                setLastName("")
                            }
                            onChange={(e) => setLastName(e.target.value)}
                            type="text"
                            error={validateField(
                                "lastNameField",
                                lastName,
                                "field",
                                lastName !== startingFieldValue
                            )}
                        />
                    </div>

                    <div className="input-holder">
                        <label>{signUpLocalization.emailLabel}</label>

                        <Field
                            value={email}
                            onBlur={() =>
                                email === startingFieldValue && setEmail("")
                            }
                            onChange={(e) => setEmail(e.target.value.trim())}
                            type="email"
                            error={validateField(
                                "emailField",
                                email,
                                "email",
                                email !== startingFieldValue
                            )}
                            moreInfo={
                                (HOTMAIL_REGEX.test(email) && (
                                    <>
                                        <b>{signUpLocalization.warning}</b>:{" "}
                                        {signUpLocalization.hotmailWarning}:{" "}
                                        <i>no-reply@prelib.ca</i>
                                    </>
                                )) ||
                                undefined
                            }
                        />
                    </div>

                    <div className="input-holder">
                        <label>{signUpLocalization.passwordLabel}</label>
                        <Field
                            value={password}
                            onBlur={() =>
                                password === startingFieldValue &&
                                setPassword("")
                            }
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={() => setPasswordDescriptionVisible(true)}
                            type="password"
                            error={validateField(
                                "passwordField",
                                password,
                                "password",
                                password !== startingFieldValue
                            )}
                        />

                        <div hidden={!passwordDescriptionVisible}>
                            <p className="disclaimer">
                                {signUpLocalization.passwordWarning}
                            </p>
                            <PasswordValidator
                                password={password ?? ""}
                                setValid={setPasswordValid}
                            />
                        </div>
                    </div>

                    <div className="input-holder">
                        <label>
                            {signUpLocalization.howDidYouHearAboutUs.title}
                        </label>

                        <Select
                            placeholder={signUpLocalization.selectOption}
                            onSelect={(selected) =>
                                !!selected &&
                                onSignUpSurveySelection(selected as Selection)
                            }
                        >
                            {shuffle(
                                signupSurveyOptions,
                                0,
                                signupSurveyOptions.length - 2
                            ).map((option) => (
                                <Option
                                    key={option}
                                    value={
                                        signUpLocalization.howDidYouHearAboutUs
                                            .options[option]
                                    }
                                ></Option>
                            ))}
                        </Select>
                        <span
                            className={`disclaimer ${styles["input-label-hint"]}`}
                        >
                            {signUpLocalization.optionalField}
                        </span>
                    </div>

                    <div hidden={!otherOptionSelected} className="input-holder">
                        <label>
                            {
                                signUpLocalization.howDidYouHearAboutUs
                                    .pleaseSpecify
                            }
                        </label>

                        <Field
                            value={otherReason}
                            onBlur={() =>
                                otherOptionSelected &&
                                otherReason !== startingFieldValue &&
                                setOtherReason(otherReason.trim())
                            }
                            onChange={(e) =>
                                setOtherReason(
                                    e.target.value.substring(
                                        0,
                                        OTHER_REASON_MAX_INDEX
                                    )
                                )
                            }
                            error={validateField(
                                "otherOptionfield",
                                otherReason,
                                "field",
                                otherOptionSelected &&
                                    otherReason !== startingFieldValue
                            )}
                        />
                    </div>

                    <hr className={styles.separator} />

                    <div className="input-holder">
                        <label>{signUpLocalization.tfaLabel}</label>
                        <p className="disclaimer">
                            {signUpLocalization.tfaContext}
                        </p>

                        <Checkbox
                            checked={tfaPreference === "email"}
                            onChange={() => onTFAOptionChanged("email")}
                            preventUncheck={true}
                        >
                            {signUpLocalization.byEmail}
                        </Checkbox>

                        <Checkbox
                            checked={tfaPreference === "sms"}
                            onChange={() => onTFAOptionChanged("sms")}
                            preventUncheck={true}
                        >
                            {signUpLocalization.bySms}
                        </Checkbox>
                    </div>

                    {(tfaPreference === "sms" && (
                        <div className="input-holder">
                            <label>{signUpLocalization.phoneNumberLabel}</label>

                            <Field
                                placeholder={
                                    signUpLocalization.phoneNumberPlaceholder
                                }
                                onChange={(e) =>
                                    setPhoneNumber(e.target.value.trim())
                                }
                                type="phone"
                                value={phoneNumber}
                                error={validateField(
                                    "phoneNumberField",
                                    phoneNumber,
                                    "phone",
                                    tfaPreference === "sms" &&
                                        phoneNumber !== startingFieldValue
                                )}
                            />
                        </div>
                    )) ||
                        validateField(
                            "phoneNumberField",
                            phoneNumber,
                            "phone",
                            false
                        )}

                    <hr className={styles.separator} />

                    <div className="input-holder">
                        <Checkbox
                            className={styles.consent}
                            checked={consentChecked}
                            onChange={setConsentChecked}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: signUpLocalization.consentMessage,
                                }}
                            ></span>
                        </Checkbox>
                    </div>
                </div>

                <Button
                    disabled={
                        !!Object.keys(fieldValidationStateRef.current).length ||
                        !firstName?.length ||
                        !lastName?.length ||
                        !email?.length ||
                        !password?.length ||
                        (tfaPreference === "sms" && !phoneNumber?.length) ||
                        (otherOptionSelected && !otherReason.trim()?.length) ||
                        !consentChecked
                    }
                    loading={loading}
                    onClick={createAccount}
                >
                    {signUpLocalization.signUpButtonText}
                </Button>
            </div>
            <Footer />
        </div>
    );
};

export default SignUpPage;
