import Tabs from "components/tabs";
import useAuthUser from "hooks/auth-user";
import useLanguage from "hooks/language";
import { PREP_STATUS } from "models/prep-status";
import { PrEPStatusTab } from "./components/prep-status-tab";
import { PrEPDeliveryTab } from "./delivery-tab";
import { MyPrEPContextProvider } from "./utils/context";

interface IMyPrepProperties {
    tabHeaderClassName?: { [key: string]: any };
}

export const MyPrEP: React.FunctionComponent<IMyPrepProperties> = (
    props: IMyPrepProperties
) => {
    const [user] = useAuthUser();
    const {
        translations: { prepSection: t },
    } = useLanguage();

    return (
        <MyPrEPContextProvider>
            <Tabs
                tabHeaderClassName={props.tabHeaderClassName ?? undefined}
                tabs={[
                    { title: t.prepStatus.title, content: <PrEPStatusTab /> },
                    {
                        title: t.prepDelivery.title,
                        content: <PrEPDeliveryTab />,
                        disabled:
                            user?.lastPrepStatus?.status !==
                            PREP_STATUS.ELIGIBLE,
                        reason: t.prepDelivery.disabledPopover,
                    },
                ]}
            />
        </MyPrEPContextProvider>
    );
};
