export const PATIENT_PORTAL_EVENT_SOURCE_IDENTIFIER = "patient";

export enum UserActionEvent {
    EmailVerificationCompleted = "Email Verification Completed",
    LoginCompleted = "Login Completed",
    LoginInitiated = "Login Initiated",
    MFASetupInitiated = "MFA Setup Initiated",
    PasswordReset = "Password Reset",
    PhoneVerificationCompleted = "Phone Verification Completed",
    PhoneVerificationInitiated = "Phone Verification Initiated",
    ProfileUpdated = "Profile Updated",
    ScreeningAppointmentBooked = "Screening Appointment Booked",
    ScreeningAppointmentCancelled = "Screening Appointment Cancelled",
    ScreeningAppointmentRescheduled = "Screening Appointment Rescheduled",
    ScreeningInformationSubmitted = "Screening Information Submitted",
    ScreeningMedicalEvaluationSubmitted = "Screening Medical Evaluation Submitted",
    ScreeningPaymentSubmitted = "Screening Payment Submitted",
    ScreeningPrepAppointmentBooked = "Screening Prep Appointment Booked",
    ScreeningPrepAppointmentCancelled = "Screening Prep Appointment Cancelled",
    ScreeningPrepAppointmentDeclined = "Screening Prep Appointment Declined",
    ScreeningPrivacyConsentAccepted = "Screening Privacy Consent Accepted",
    ScreeningPrivacyConsentDeclined = "Screening Privacy Consent Declined",
    ScreeningRequestedInitiated = "Screening Request Initiated",
    SignUpCompleted = "Sign Up Completed",
    SignUpInitiated = "Sign Up Initiated",
}
