import {
    Experiment,
    ExperimentClient,
    ExperimentUser,
} from "@amplitude/experiment-js-client";
import { generateAnalyticsUserID } from "utils/analytics/user-id";
import { IFeatureManager } from "..";

export enum ExperimentServerZone {
    US = "us",
    EU = "eu",
}

export enum FeatureFlag {
    On = "on",
    Off = "off",
}

export class AmplitudeFlagAndExperimentManager implements IFeatureManager {
    private _experimentClient: ExperimentClient;

    public constructor(
        private readonly deploymentKey: string,
        private readonly experimentServerZone?: ExperimentServerZone,
        private readonly enableDebugger: boolean = false
    ) {
        if (!deploymentKey) {
            throw new Error("deploymentKey");
        }

        this._experimentClient = Experiment.initialize(this.deploymentKey, {
            debug: this.enableDebugger,
            serverZone: this.experimentServerZone ?? ExperimentServerZone.EU,
        });
    }

    public async start(): Promise<boolean> {
        try {
            await this._experimentClient.start();

            return true;
        } catch (error) {
            console.error("Cannot start Amplitude SDK");

            return false;
        }
    }

    public async configureUser(email?: string): Promise<void> {
        const experimentUser: ExperimentUser = {};

        if (email) {
            experimentUser.user_id = generateAnalyticsUserID(email);
        }

        await this._experimentClient.fetch(experimentUser);
    }

    public isFeatureFlagEnabled(name: string, expectedValue: any): boolean {
        const flagVariant = this._experimentClient.variant(name);

        return flagVariant.value === expectedValue;
    }
}
